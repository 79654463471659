import React from 'react';
import { Flex, StackProps } from '@chakra-ui/react';

export type PageStackProps = {
  children: React.ReactNode;
} & StackProps;

export const PageStack = React.forwardRef<HTMLDivElement, PageStackProps>(
  function PageStack(
    { children, gap, spacing, align = 'flex-start', ...props },
    ref,
  ) {
    return (
      <Flex
        gap={gap ?? spacing ?? 2}
        direction="column"
        h="full"
        px="4"
        pb="4"
        align={align}
        {...props}
        ref={ref}
      >
        {children}
      </Flex>
    );
  },
);
