import { QueryObserver } from '@tanstack/react-query';
import { fromCallback, fromPromise } from 'xstate5';

import { $API, apiClient } from '../clients';
import { StriveApi } from '../endpoints';
import { queryClient } from './query-client';

/**
 * Creates an XState callback actor which fetches data from a Zodios endpoint
 * and subscribing to updates, playing nicely with other react hooks
 * which are reading the same data
 */
export function xstateZodiosCallback<
  ApiAlias extends StriveApi['FetchAliases'],
>(options: { alias: ApiAlias }) {
  return fromCallback<
    { type: 'invalidate' },
    StriveApi['/'][ApiAlias]['RequestOptions']
  >(({ sendBack, receive, input }) => {
    const updater = (data: StriveApi['/'][ApiAlias]['Response']) => {
      sendBack({ type: `${options.alias}.result`, data });
    };

    const queryKey = $API.getKeyByAlias(
      options.alias as StriveApi['FetchAliases'],
      input,
    );

    const initialState =
      queryClient.getQueryState<StriveApi['/'][ApiAlias]['Response']>(queryKey);
    if (initialState && initialState.status === 'success') {
      updater(initialState.data ?? null);
    }

    const observer = new QueryObserver<StriveApi['/'][ApiAlias]['Response']>(
      queryClient,
      {
        queryKey,
        queryFn: () =>
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          apiClient[options.alias]?.(input as any) as Promise<
            StriveApi['/'][ApiAlias]['Response']
          >,
        enabled: true,
      },
    );

    const unsubscribe = observer.subscribe((result) => {
      updater(result.data ?? null);
    });

    receive((event) => {
      if (event.type === 'invalidate') {
        queryClient.invalidateQueries(queryKey);
      }
    });

    return () => unsubscribe();
  });
}

/**
 * Creates an XState promise actor which fetches data from a Zodios endpoint
 * ... hitting the React Query cache.
 */
export function xstateZodiosPromise<
  ApiAlias extends StriveApi['FetchAliases'],
>(options: { alias: ApiAlias; staleTime?: number }) {
  return fromPromise<
    StriveApi['/'][ApiAlias]['Response'],
    StriveApi['/'][ApiAlias]['RequestOptions']
  >(({ input }) => {
    return queryClient.fetchQuery({
      queryKey: $API.getKeyByAlias(
        options.alias as StriveApi['FetchAliases'],
        input,
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      queryFn: () => apiClient[options.alias]?.(input as any),
      staleTime: options.staleTime,
    });
  });
}
