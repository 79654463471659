import type { WritableKeys } from 'utility-types';

import { isObjectWithKey, LooseAutocomplete } from '../utils';

export function isAuthToken(token: unknown): token is AuthResponse {
  return isObjectWithKey(token, 'access') && 'refresh' in token;
}

export function isSignupToken(token: unknown): token is SignupAuthResponse {
  return isObjectWithKey(token, 'token');
}

export type AuthResponse = {
  access: string;
  refresh: string;
};

export type SignupAuthResponse = {
  token: string;
};

export type LoginResponse = AuthResponse | SignupAuthResponse;

export type ProfileFoundResponse = {
  email: string;
  first_name: string;
  last_name: string;
  team_name: string;
  token: string;
};

export type PreRegisterTeam = {
  name: string;
  specialties: { id: string; name: string }[];
  license_types: { id: string; name: string }[];
};

// What is returned from the API
export type UserProfileResponse = {
  user: UserData<string>;
  profile: UserProfileData;
};

// The user object is merged into the top level object within the app
export type UserProfile<DateType = Date> = UserData<DateType> & {
  profile: UserProfileData;
};

export type UserData<DateType = Date> = {
  readonly id: number;
  username: string;
  first_name: string;
  last_name: string;
  email?: string;
  readonly last_login: DateType | null;
  readonly is_staff: boolean;
};

export type UserProfileData = {
  phone_number: string | null;
  readonly team: string | null;
  readonly team_id: number | null;
  goal: number | null;
  readonly goal_text: string | null;
  send_email: boolean | null;
  send_sms: boolean | null;
  send_push: boolean | null;
  preferred_name: string | null;
  readonly impersonated_by: number | null;
  readonly stream_token: string;
  readonly stream_username: string;
  readonly channel_name_with_coach: string | null;
  readonly team_coach_stream_username: string | null;
  readonly roles: Array<
    LooseAutocomplete<'coach' | 'leader' | 'launchpad_guide'>
  >;
  primary_care_environment: string | null;
  specialty: string | null;
  license_type: string | null;
  wearable: {
    provider: 'whoop' | 'oura' | 'striveware';
    user_id: string | null;
    password: string | null;
    username: string | null;
    email: string | null;
    status: 'valid' | 'invalid' | 'revoked' | 'expired' | null;
    errors: { title: string; message: string; action: string | null } | null;
    url: string | null;
    last_synced_at: Date | null;
    is_synced_today: boolean | null;
    device_address: string | null;
    device_name: string | null;
    display_name: string | null;
  }[];
};

export type UserUpdateProfile = Pick<
  UserProfileData,
  WritableKeys<UserProfileData>
>;

export type UserUpdateBody = Partial<{
  user: Pick<UserData, WritableKeys<UserData>>;
  profile: Partial<UserUpdateProfile>;
}>;
